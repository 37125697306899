@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Oswald:wght@200..700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
}

body {
  font-family: "Oswald", sans-serif !important;
  margin: 0; /* Đảm bảo không có khoảng cách giữa các phần tử */
  padding: 0; /* Đảm bảo không có khoảng cách giữa các phần tử */
  overflow-x: hidden; /* Ngăn cuộn ngang */
  overflow-y: scroll; /* Bắt buộc cuộn dọc luôn khả dụng */
}

.link-custom {
  text-decoration: none;
  color: black;
}

.link-custom:hover {
  cursor: pointer;
}

/* line */
.custom-border-header {
  border-bottom: 12px solid rgba(254, 9, 14, 1) !important;
  max-width: 600px;
  margin: 15px auto 0;
}

.custom-border-content {
  border-bottom: 4px solid rgba(254, 9, 14, 1) !important;
}

/* card content */
.custom-title-vni {
  color: black;
  font-family: Arial;
  font-size: 30px;
  font-weight: 700;
  word-wrap: break-word;
}

.custom-title-eng {
  color: red;
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
  word-wrap: break-word;
}

.custom-content-vni {
  color: black;
  font-family: Arial;
  font-size: 17px;
  font-weight: 400;
}

.custom-content-eng {
  color: red;
  font-family: Arial;
  font-size: 17px;
  font-weight: 400;
}

/* transition */
.custom-transition {
  transition: all 0.3s ease-in-out;
}

/* border */
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(95, 99, 104);
  border: 2px solid #fff; /* Thêm viền trắng cho vòng tròn */
  border-radius: 50%;
  width: 50px; /* Đặt kích thước vòng tròn */
  height: 50px; /* Đặt kích thước vòng tròn */
}

.icon-container a {
  display: block;
}

.card-body {
  padding: 3px !important;
}
