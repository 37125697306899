.button-contact-vr {
  position: fixed;
  z-index: 99999;
  bottom: 0;
  right: 0;
}

.button-contact {
  transition: 1.6s all;
  -webkit-transition: 1.6s all;
  position: relative;
  margin-top: -5px;
}

.phone-vr {
  position: relative;
  visibility: visible;
  background-color: transparent;
  width: 90px;
  height: 90px;
  cursor: pointer;
  z-index: 11;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transition: visibility 0.5s;
  left: 0;
  bottom: 0;
  display: block;
}

.phone-vr-circle-fill {
  box-shadow: 0 0 0 0 #2196f3;
  background-color: rgba(33, 150, 243, 0.7);
  width: 65px;
  height: 65px;
  top: 12px;
  left: 12px;
  position: absolute;
  border-radius: 50%;
  border: 2px solid transparent;
  -webkit-animation: phone-vr-circle-fill 2.3s infinite ease-in-out;
  animation: phone-vr-circle-fill 2.3s infinite ease-in-out;
  transition: all 0.5s;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: zoom 1.3s infinite;
  animation: zoom 1.3s infinite;
}

.phone-vr-img-circle {
  background-color: #2196f3;
  width: 40px;
  height: 40px;
  line-height: 40px;
  top: 25px;
  left: 25px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
  animation: phone-vr-circle-fill 1s infinite ease-in-out;
}

.phone-vr-img-circle-img {
  max-height: 25px;
  max-width: 27px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

@keyframes phone-vr-circle-fill {
  0% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
