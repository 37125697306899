.slider {
  width: 100%; /* Chiếm toàn bộ chiều rộng của container */
  max-width: 500px; /* Giới hạn kích thước tối đa */
  aspect-ratio: 1 / 1; /* Đảm bảo tỷ lệ vuông */
  position: relative;
}

.slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.leftArrow,
.rightArrow {
  display: none;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(32, 33, 36, 0.3);
  cursor: pointer;
  opacity: 1;
  transition: background-color 0.3s ease;
  z-index: 1;
  border: 0 !important;
}

.leftArrow:hover,
.rightArrow:hover {
  background-color: rgb(32, 33, 36, 0.7);
}

.slider:hover .leftArrow,
.slider:hover .rightArrow {
  display: block;
}

.leftArrow {
  left: 20px;
}

.rightArrow {
  right: 20px;
}

/* dot */
.all-dots {
  margin-top: 20px;
  max-width: 500px;
  display: flex;
  justify-content: center;
  position: relative;
}

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0px 6px;
  background-color: rgb(189, 193, 198);
  border-radius: 50%;
  display: inline-block;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Transition for smooth effect */
  opacity: 0.6; /* Default opacity */
}

.dot:hover {
  transform: scale(1.2);
}

.active-dot {
  background-color: rgba(254, 9, 14, 1);
  transform: scale(1.5); /* Makes the active dot bigger */
  opacity: 1; /* Makes the active dot fully visible */
}

/* When not active, dots are smaller and less opaque */
.inactive-dot {
  transform: scale(1); /* Keep size for inactive dots */
  opacity: 0.6;
}

.active-dot:hover {
  transform: scale(1.2); /* Slight hover effect to highlight */
}
